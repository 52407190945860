import React, { useEffect, useState } from "react";
import logo from "../assets/logo/logo.png";
import user from "../assets/user.jpg";
import Settings from "./Settings";

import MenuItem from "./MenuItem";
import logoImg from "../assets/logo/favicon.png"
import ChainList from "./ChainList";

/**
 * @author
 * @function SideMenu
 **/

// added more menuItems for testing

const label = () => {
  return () => {
    
  }
}

export const menuItems = [
   { name: "Chain List", exact: true ,to: `/`, iconClassName: "fab fa-google-wallet", items: <ChainList></ChainList> },
  
  { name: "Settings", to: `/design-3`, iconClassName: "fas fa-cog", items: <Settings></Settings> },

  



 
];

const SideMenu = (props) => {
  const [inactive, setInactive] = useState(false);


  const close = () => {
    sessionStorage.removeItem("token");
    window.location.reload(false);
  }

  useEffect(() => {
    if (inactive) {
      removeActiveClassFromSubMenu();
    }

    props.onCollapse(inactive);
  }, [inactive]);

  //just an improvment and it is not recorded in video :(
  const removeActiveClassFromSubMenu = () => {
    document.querySelectorAll(".sub-menu").forEach((el) => {
      el.classList.remove("active");
    });
  };


  useEffect(() => {
    let menuItems = document.querySelectorAll(".menu-item");
    menuItems.forEach((el) => {
      el.addEventListener("click", (e) => {
        const next = el.nextElementSibling;
        removeActiveClassFromSubMenu();
        menuItems.forEach((el) => el.classList.remove("active"));
        el.classList.toggle("active");
        if (next !== null) {
          next.classList.toggle("active");
        }
      });
    });
  }, []);

  return (
    <div className={`side-menu ${inactive ? "inactive" : ""}`}>
      <div className="top-section">
        { !inactive ?  <div >
          <img src={logo} className="logoImg" alt="webscript" />
        </div> : <div >
          <img src={logoImg} style={{margin: "0 10px", width: "30px"}} alt="webscript" />
        </div> }
        <div onClick={() => setInactive(!inactive)} >
          {inactive ? (
                  <div className="toggle-menu-close">
                  <i className="bi bi-arrow-left-square-fill"></i>
                   </div>
          ) : (
               <div className="toggle-menu-btn">
                <i className="fas fa-bars"></i>
                </div>
          )}
        </div>
      </div>

      <div className="search-controller">
        <div>
          {!inactive ? <p className="emailName"></p> : "" }  
         
        </div>
      
        
      </div>

      <div className="divider"></div>

      <div className="main-menu">
        <ul>
          {menuItems.map((menuItem, index) => (
            <MenuItem
              key={index}
              name={menuItem.name}
              exact={menuItem.exact}
              to={menuItem.to}
              subMenus={menuItem.subMenus || []}
              iconClassName={menuItem.iconClassName}
              miniIcon={menuItem.miniIcon}
              onClick={(e) => {
                if (inactive) {
                  setInactive(false);
                }
              }}
              
            />
            
          ))}
         
        
        </ul>
       { !inactive ?  <div className="logout" onClick={() => close()}>
        <li> <i className="fas fa-sign-out-alt"></i> Logout </li>
       </div> :  <div className="logout" onClick={() => close()}>
         <i className="fas fa-sign-out-alt"></i>
       </div> }
      </div>

     
    </div>
  );
};

export default SideMenu;
