import "./App.css";
import SideMenu, { menuItems } from "./components/SideMenu";



import { BrowserRouter as Router, Route } from "react-router-dom";
import { useState } from "react";
import HeaderBar from './components/HederBar';

import Login from "./components/Login";

import AddChain from "./components/AddChain";
import jwt_decode from "jwt-decode";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




function App() {

  const [inactive, setInactive] = useState(false);
  const token = sessionStorage.getItem("token");

  if (token !== null) {
    var decoded = jwt_decode(token);
    let currentDate = new Date();
    const expiration = new Date(decoded.iat + 3000);
    if (new Date(expiration.getTime()) < new Date(currentDate.getTime() / 1000)) {
      sessionStorage?.removeItem("token")
      toast.success("Session Logout")
      window.location.href = "/";
    } else {
    }
  }





  return (

    <div className="App">
      <ToastContainer />
      <Router>
        {token === null ? <Login></Login> :
          <div>
            <SideMenu
              onCollapse={(inactive) => {
                setInactive(inactive);
              }}
            />
            <div className={`container ${inactive ? "inactive" : ""}`}>
              <HeaderBar></HeaderBar>
              <Route path="/content/addchain/:id" exact><AddChain></AddChain></Route>
              {menuItems.map((menu, index) => (
                <>
                  <Route key={menu.name} exact={menu.exact} path={menu.to}>
                 
                    <h1>{menu.items}</h1>
                  </Route>
                  {menu.subMenus && menu.subMenus.length > 0
                    ? menu.subMenus.map((subMenu, i) => (
                      <Route key={subMenu.name} path={subMenu.to}>
                        <h1>{subMenu.compo}</h1>
                      </Route>
                    ))
                    : null}
                </>
              ))}
              </div>
          </div>
        }
      </Router>
    </div>
  );
}

export default App;
