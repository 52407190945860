import React, { useEffect, useState } from 'react'
import Footer from './Footer';
import { Link } from 'react-router-dom';
import Web3 from 'web3';
import { socket } from './SocketStore/Socket';

function ChainList() {

    const [chain, setChain] = useState([]);


    useEffect(() => {

        socket.emit("getAllChains")

    }, [])

    useEffect(() => {


        socket.on("getAllChains-res", (data) => {
          
            setChain(...chain, data)
        })

    }, [socket])

   

   






    return (
        <div>
            <div className="content-wrapper">

                <section className="content-header">
                    <div className="container-fluid">

                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h5>Chain List</h5>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right text">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active">Chain List</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title"></h3>
                                        <div className="box-tools cards">
                                            <h3 style={{ fontSize: "19px" }}>Chain List</h3>


                                        </div>
                                    </div>


                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table id="example" className="table table-bordered table-striped table-hover" datatable
                                            >
                                                <thead>
                                                    <tr className='tr'>

                                                        <th>S.no </th>
                                                        <th>Chain Name</th>
                                                        <th>Chain Symbol</th>
                                                        <th>Contract Address</th>
                                                        <th>Actions</th>

                                                    </tr>
                                                </thead>

                                                <tbody>

                                                    {chain?.map((e, index) => (

                                                        <tr className='tr'>
                                                            <td>{index + 1}</td>
                                                            <td>{e?.chain_name}</td>
                                                            <td>{e?.chain_symbol}</td>
                                                            <td>{e?.bridge_Contract?.Contract_address}</td>
                                                            <td>
                                                                <Link to={{ pathname: `/content/addchain/${e?._id}`, state: { contract: `${e?.bridge_Contract?.Contract_address}`, chain_symbol: `${e?.chain_symbol}` } }}>
                                                                    <button type="button" className="btn btn-primary pull-right" style={{ color: "#111", padding: "8px 12px ", width: "100%" }}>
                                                                        <i className="fa fa-plus"></i> &nbsp; Edit
                                                                    </button>
                                                                </Link></td>

                                                        </tr>
                                                    ))}



                                                </tbody>


                                            </table>
                                        </div>
                                    </div>

                                </div>



                            </div>

                        </div>


                    </div>

                </section>
                <Footer></Footer>
            </div>
        </div>
    )
}

export default ChainList