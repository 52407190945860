import React, { useEffect, useState } from 'react'
import logo from "../assets/logo/logo.png";
import { socket } from './SocketStore/Socket';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';





import logoImg from "../assets/logo/logo.png"



function Login() {

    const [username, setUserName] = useState("")
    const [password, setPassword] = useState("")


    const [showPassword, setShowPassword] = useState(null);


    const handleSubmit = (e) => {

        e.preventDefault()

        var loginDetails = {
            email: username,
            password: password
        };

      


        socket.emit("login", loginDetails)
    }

    useEffect(() => {

        socket.on("login-res", (data) => {
           
            if (data?.message === "true") {

                toast.success(data?.status)
                setTimeout(() => {
                    window.location.reload();
                }, [1500])

            } else {
                toast.error(data)
            }
            sessionStorage.setItem("token", data?.token)
        })

    }, [socket])


    return (
        <div className='fullsc'>


            <div className="login-box">
                <div className="login-logo">
                    <img className="mb-3 " src={logo} alt="" /> <b> | ADMIN </b>

                </div>
                <div className="card">


                    <div className="card-body login-card-body">

                        <p className="login-box-msg">Sign in to start your session</p>

                        <form>
                            <div className="form-group">

                                <div className="input-group">
                                    <input type="text" formControlName="email" className="form-control" onChange={(e) => setUserName(e.target.value)} placeholder='Email' />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-envelope"></span>
                                        </div>
                                    </div>
                                    <div className="error invalid-feedback">
                                        <div></div>
                                        <div>Email must be a valid email address</div>
                                    </div>
                                </div>
                            </div>



                            <div className="input-group">
                                <input formControlName="password" type={showPassword ? 'text' : 'password'} onChange={(e) => setPassword(e.target.value)} className="form-control" placeholder='Password' />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span onClick={() => setShowPassword(!showPassword)} className="eyeic"> {!showPassword ? <i className="fas fa-eye-slash"></i> : <i className="fa fa-eye"></i>} </span>

                                    </div>
                                </div>

                            </div>
                            <div className="text text-danger">
                                <div></div>
                            </div>

                            <div className="btnMain">

                                <button type="submit" onClick={(e) => handleSubmit(e)} className="btn btn-md btn-primary btn-block">Login</button>

                            </div>
                        </form>
                        {/* <div className='items'>
            <p className="mb-1">
               <a href="javascript:void(0);">I forgot my password</a> 
            </p>
            <p className="mb-0">
               <a routerLink="/auth/register" className="text-center">Create an account</a> 

            </p>

            </div> */}


                    </div>

                </div>
            </div>
        </div>

    )
}

export default Login