import React from 'react';




function Footer() {

    
    return (
        <div>
            <footer className="main-footer footerPage">
                <div className="float-right d-none d-sm-block">
     
                </div>
                <strong>Copyright 2022. Realchain.io All Rights Reserved.</strong>
            </footer>
        </div>
    )

  
}
export default Footer
