import React, { useState, useEffect } from 'react'
import Footer from './Footer';
import { Link, useParams, useLocation } from 'react-router-dom';
import { socket } from "./SocketStore/Socket";
import Web3 from 'web3';
import { useFormik } from 'formik';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import * as yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SpinnerCircularFixed } from "spinners-react";
import Form from 'react-bootstrap/Form';
import { getMessageFromCode, errorCodes } from 'eth-rpc-errors'



function AddChain(props) {

    const [tokenList, setTokenList] = useState([])
    const id = useParams();
    const location = useLocation();
    const [show, setShow] = useState(false);
    const [showSecond, setShowSecond] = useState(false);
    const [amount, setAmount] = useState("");
    const [address, setAddress] = useState("")
    const [contractToken, setContractToken] = useState("")
    const [tokenAbi, SetTokenABI] = useState("")
    const [tokenID, SetTokenID] = useState("")
    const [toggle, setToggle] = useState(false);

    const [statusDate, setStatusData] = useState()

    const [contractTokenSecond, setContractTokenSecond] = useState("")
    const [tokenAbiSecond, SetTokenABISecond] = useState("")
    const [tokenIDSecond, SetTokenIDSecond] = useState("")

    const [chainIDValue, setChainIDValue] = useState("")


    const [loading, setLoading] = useState(false)

    const handleClose = () => setShow(false);
    const handleShow = (contract, abi, id, chainId) => {
        setContractToken(contract)
        SetTokenABI(abi)
        SetTokenID(id)
        setChainIDValue(chainId)
        setShow(true);
    }

    const handleCloseSecond = () => setShowSecond(false);
    const handleShowSecond = (contract, abi, id) => {
        setContractTokenSecond(contract)
        SetTokenABISecond(abi)
        SetTokenIDSecond(id)
        setShowSecond(true);
    }
    const contract = props?.location?.state;
    useEffect(() => {

        const details = {
            "id": `${id?.id}`
        }

        socket.emit("tokenAdmin", details)


    }, [])
    useEffect(() => {
        socket.on("tokenAdmin-res", (data) => {

            setTokenList(data)
        })
    }, [socket])


    const Swapschema = yup.object().shape({
        addLiquidity: yup.number().positive()
            .moreThan(0, "Add Liquidity must be positive number")
            .min(0, "Add Liquidity must be positive number")
            .test("check 0", "Add Liquidity must be positive number", (data) => data > 0),
    });
    const SwapschemaTwo = yup.object().shape({
        amount: yup.number().positive()
            .moreThan(0, "Minimum Token must be positive number")
            .min(0, "Minimum Token must be positive number")
            .test("check 0", "Minimum Token must be positive number", (data) => data > 0),
    });

    const addNetwork = async (chainId, chain_name, rpcurl, blockurl, name, symbol, decimals) => {
        try {
            await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                    {
                        chainId: Web3.utils.toHex(chainId),
                        chainName: chain_name,
                        rpcUrls: [rpcurl],
                        blockExplorerUrls: [blockurl],
                        nativeCurrency: {
                            name: name,
                            symbol: symbol,
                            decimals: decimals
                        }
                    }]
            });
        } catch (addError) {

        }
    }


    const formik = useFormik({
        initialValues: {
            addLiquidity: "",
        },
        validationSchema: Swapschema,
        onSubmit: values => {
            contractCall()
        }
    });

    const formikTwo = useFormik({
        initialValues: {
            amount: "",
        },
        validationSchema: SwapschemaTwo,
        onSubmit: values => {
            updateToken()
        },
    });
    const withdrawNativCoin = async (chain, market, contract, address, chainId, id) => {
        if (chain === "BNB" || chain === "ETH") {
            let authoized = await market.methods.isAddressAuthorizedWithdraw(address).call();
            if (authoized) {
                market.methods.withdrawNativeCoinOwner().send({
                    from: address,

                }, function (err) {
                    if (err && err.code === 4001) {
                        toast.error("User denied transaction signature")
                        setLoading(false)
                    }
                }).then(async res => {
                    const getBalance = await market.methods.getbalance().call()
                    const wei = window.web3.utils.fromWei(getBalance, "ether")
                    const add = {
                        "tokenId": id,
                        "amount": wei
                    }
                    socket.emit("update-token-balance", add)
                    setLoading(false)
                    toast.success("Successfully Added Withdraw")
                    setTimeout(() => (window.location.href = `/content/addchain/${chainId}`), 1000);
                })
            } else {
                toast.error("Connected address not authorized for withdraw");
                setLoading(false)
            }
        }

    }
    const withdrawToken = async (contractAddress, abi, id, chainId) => {

        // setShowSecond(!true)
        setLoading(true)

        if (window.ethereum) {
            try {
                let web3 = new Web3(window.ethereum);
                const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                let getChainID = await web3.eth.getChainId();
                await setAddress(accounts[0])
                try {
                    if (window.web3) {
                        await new Web3(window.web3.currentProvider);
                        window.web3 = new Web3(window.web3.currentProvider);
                        if (location.state.chain_symbol === "RCL") {
                            try {
                                await window.ethereum.request({
                                    method: 'wallet_switchEthereumChain',
                                    params: [{ chainId: web3.utils.toHex(121) }]
                                });

                                const market = await new window.web3.eth.Contract(
                                    JSON.parse(process.env.REACT_APP_BRIDGECONTRACT_ABI), location?.state?.contract
                                );
                                let authoized = await market.methods.isAddressAuthorizedWithdraw(accounts[0]).call();
                                if (authoized) {
                                    var tokenContract = new web3.eth.Contract(JSON.parse(abi), contractAddress);
                                    market.methods.withdrawOwner(contractAddress).estimateGas(
                                        {
                                            from: accounts[0],
                                            to: contractAddress,
                                        }, function (err, gas) {
                                            let errore = err.toString()
                                            if (errore.indexOf('Internal JSON-RPC error.') > -1) {
                                                errore = errore.replace('\n', '').replace("Error: ", '').replace('Internal JSON-RPC error.', '')
                                                errore = JSON.parse(errore)
                                            }
                                            setLoading(false)
                                            toast.error(errore.message)
                                        }
                                    )
                                    const gasPrice = await market.methods.withdrawOwner(contractAddress).estimateGas(
                                        {
                                            from: accounts[0],
                                            to: contractAddress,
                                        }
                                    )
                                    market.methods.withdrawOwner(contractAddress).send({
                                        from: accounts[0],
                                        to: contractAddress,
                                        gas: `${gasPrice}`
                                    }, function (err) {
                                        if (err && err.code === 4001) {
                                            toast.error("User denied transaction signature")
                                            setLoading(false)
                                        }
                                    }).then(async res => {
                                        const getBalance = await market.methods.getContractBalance(contractAddress).call()
                                        const wei = window.web3.utils.fromWei(getBalance, "ether")
                                        const add = {
                                            "tokenId": id,
                                            "amount": wei
                                        }
                                        socket.emit("update-token-balance", add)
                                        setLoading(false)
                                        toast.success("Successfully Added Withdraw")
                                        setTimeout(() => (window.location.href = `/content/addchain/${chainId}`), 1000);
                                    })
                                } else {
                                    toast.error("Connected address not authorized for withdraw");
                                    setLoading(false)
                                }
                            } catch (err) {
                                if (err.code === 4902) {
                                    addNetwork(
                                        121,
                                        "Real TESTNET",
                                        "https://dataseed1.rclscan.com/",
                                        "https://testnet.rclscan.com",
                                        "tREAL",
                                        "tREAL",
                                        18
                                    )
                                    setLoading(false)
                                }
                                if (err.code === 4001) {
                                    setLoading(false)
                                    toast.error(err.message)
                                }
                            }
                        } else if (location.state.chain_symbol === "BSC") {
                            try {
                                await window.ethereum.request({
                                    method: 'wallet_switchEthereumChain',
                                    params: [{ chainId: web3.utils.toHex(56) }]
                                });

                                const market = await new window.web3.eth.Contract(
                                    JSON.parse(process.env.REACT_APP_BRIDGECONTRACT_ABI), location?.state?.contract
                                );
                                if (!contractAddress) {
                                    withdrawNativCoin("BNB", market, location?.state?.contract, accounts[0], chainId, id);
                                } else {
                                    let authoized = await market.methods.isAddressAuthorizedWithdraw(accounts[0]).call();
                                    if (authoized) {
                                        var tokenContract = new web3.eth.Contract(JSON.parse(abi), contractAddress);
                                        market.methods.withdrawOwner(contractAddress).estimateGas(
                                            {
                                                from: accounts[0],
                                                to: contractAddress,
                                            }, function (err, gas) {
                                            }
                                        )
                                        market.methods.withdrawOwner(contractAddress).estimateGas(
                                            {
                                                from: accounts[0],
                                                to: contractAddress,
                                            }, function (err, gas) {
                                                let errore = err.toString()
                                                if (errore.indexOf('Internal JSON-RPC error.') > -1) {
                                                    errore = errore.replace('\n', '').replace("Error: ", '').replace('Internal JSON-RPC error.', '')
                                                    errore = JSON.parse(errore)
                                                }
                                                setLoading(false)
                                                toast.error(errore.message)
                                            }
                                        )

                                        const gasPrice = await market.methods.withdrawOwner(contractAddress).estimateGas(
                                            {
                                                from: accounts[0],
                                                to: contractAddress,
                                            }
                                        )

                                        market.methods.withdrawOwner(contractAddress).send({
                                            from: accounts[0],
                                            to: contractAddress,
                                            gas: `${gasPrice}`,

                                        }, function (err) {

                                            if (err && err.code === 4001) {

                                                toast.error("User denied transaction signature")
                                                setLoading(false)
                                            }

                                        }).then(async res => {

                                            const getBalance = await market.methods.getContractBalance(contractAddress).call()
                                            const wei = window.web3.utils.fromWei(getBalance, "ether")
                                            const add = {
                                                "tokenId": id,
                                                "amount": wei
                                            }
                                            socket.emit("update-token-balance", add)
                                            setLoading(false)
                                            toast.success("Successfully Added Withdraw")
                                            setTimeout(() => (window.location.href = `/content/addchain/${chainId}`), 1000);

                                        })
                                    } else {
                                        toast.error("Connected address not authorized for withdraw");
                                        setLoading(false)
                                    }
                                }

                            } catch (err) {
                                if (err.code === 4902) {
                                    addNetwork(
                                        97,
                                        "BNB TESTNET",
                                        "https://data-seed-prebsc-1-s3.binance.org:8545",
                                        "https://testnet.bscscan.com",
                                        "BNB",
                                        "BNB",
                                        18
                                    )
                                    setLoading(false)
                                }
                                if (err.code === 4001) {
                                    toast.error(err.message)
                                    setLoading(false)
                                }
                            }
                        } else {
                            try {
                                await window.ethereum.request({
                                    method: 'wallet_switchEthereumChain',
                                    params: [{ chainId: web3.utils.toHex(5) }]
                                });
                                const market = await new window.web3.eth.Contract(
                                    JSON.parse(process.env.REACT_APP_BRIDGECONTRACT_ABI), location?.state?.contract
                                );
                                if (!contractAddress) {
                                    withdrawNativCoin("ETH", market, location?.state?.contract, accounts[0], chainId, id);
                                } else {
                                    let authoized = await market.methods.isAddressAuthorizedWithdraw(accounts[0]).call();
                                    if (authoized) {
                                        var tokenContract = new web3.eth.Contract(JSON.parse(abi), contractAddress);
                                        market.methods.withdrawOwner(contractAddress).estimateGas(
                                            {
                                                from: accounts[0],
                                                to: contractAddress,
                                            }, function (err, gas) {
                                            }
                                        )
                                        market.methods.withdrawOwner(contractAddress).estimateGas(
                                            {
                                                from: accounts[0],
                                                to: contractAddress,
                                            }, function (err, gas) {
                                                let errore = err.toString()
                                                if (errore.indexOf('Internal JSON-RPC error.') > -1) {
                                                    errore = errore.replace('\n', '').replace("Error: ", '').replace('Internal JSON-RPC error.', '')
                                                    errore = JSON.parse(errore)
                                                }
                                                setLoading(false)
                                                toast.error(errore.message)
                                            }
                                        )

                                        const gasPrice = await market.methods.withdrawOwner(contractAddress).estimateGas(
                                            {
                                                from: accounts[0],
                                                to: contractAddress,
                                            }
                                        )

                                        market.methods.withdrawOwner(contractAddress).send({
                                            from: accounts[0],
                                            to: contractAddress,
                                            gas: `${gasPrice}`,

                                        }, function (err) {

                                            if (err && err.code === 4001) {

                                                toast.error("User denied transaction signature")
                                                setLoading(false)
                                            }

                                        }).then(async res => {

                                            const getBalance = await market.methods.getContractBalance(contractAddress).call()
                                            const wei = window.web3.utils.fromWei(getBalance, "ether")
                                            const add = {
                                                "tokenId": id,
                                                "amount": wei
                                            }
                                            socket.emit("update-token-balance", add)
                                            setLoading(false)
                                            toast.success("Successfully Added Withdraw")
                                            setTimeout(() => (window.location.href = `/content/addchain/${chainId}`), 1000);

                                        })
                                    } else {
                                        toast.error("Connected address not authorized for withdraw");
                                        setLoading(false)
                                    }
                                }

                            } catch (err) {
                                if (err.code === 4902) {
                                    addNetwork(
                                        5,
                                        "BNB TESTNET",
                                        "https://data-seed-prebsc-1-s3.binance.org:8545",
                                        "https://testnet.bscscan.com",
                                        "BNB",
                                        "BNB",
                                        18
                                    )
                                    setLoading(false)
                                }
                                if (err.code === 4001) {
                                    toast.error(err.message)
                                    setLoading(false)
                                }
                            }
                        }
                    }
                } catch (err) {

                }
            } catch (error) {
            }
        } else if (window.web3) {
            let web3 = new Web3(window.web3.currentProvider);
        } else {
            window.alert('Non-Ethereum browser detected. Please install MetaMask plugin');
        };
    }

    const updateToken = async () => {
        setShowSecond(!true)
        setLoading(true)
        if (window.ethereum) {
            try {
                let web3 = new Web3(window.ethereum);
                const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                let getChainID = await web3.eth.getChainId();
                await setAddress(accounts[0])
                try {
                    if (window.web3) {
                        await new Web3(window.web3.currentProvider);
                        window.web3 = new Web3(window.web3.currentProvider);
                        if (location.state.chain_symbol === "RCL") {
                            try {
                                await window.ethereum.request({
                                    method: 'wallet_switchEthereumChain',
                                    params: [{ chainId: web3.utils.toHex(121) }]
                                });
                                const market = await new window.web3.eth.Contract(
                                    JSON.parse(process.env.REACT_APP_BRIDGECONTRACT_ABI), location?.state?.contract
                                );
                                var tokenContract = new web3.eth.Contract(JSON.parse(tokenAbiSecond), contractTokenSecond);
                                console.log("decimals", tokenContract.methods.decimals().call());
                                const wei = window.web3.utils.toWei((formikTwo.values.amount).toString(), "ether")

                                market.methods.updateMinmumamount(wei).send({
                                    from: accounts[0],
                                    to: contractTokenSecond,
                                    value: "0x0",
                                    data: tokenContract.methods.transfer(location?.state?.contract, wei).encodeABI()
                                }, function (err) {
                                    if (err && err.code === 4001) {
                                        toast.error("User denied transaction signature")
                                        setLoading(false)
                                    }

                                }).then(res => {
                                    setLoading(false)
                                    toast.success("Update Minimum Token Added")
                                })
                            } catch (err) {
                                if (err.code === 4902) {
                                    addNetwork(
                                        121,
                                        "Real TESTNET",
                                        "https://dataseed1.rclscan.com/",
                                        "https://testnet.rclscan.com",
                                        "tREAL",
                                        "tREAL",
                                        18
                                    )
                                }
                            }
                        } else if (location.state.chain_symbol === "BSC") {
                            try {
                                await window.ethereum.request({
                                    method: 'wallet_switchEthereumChain',
                                    params: [{ chainId: web3.utils.toHex(56) }]
                                });
                                const market = await new window.web3.eth.Contract(
                                    JSON.parse(process.env.REACT_APP_BRIDGECONTRACT_ABI), location?.state?.contract
                                );
                                var tokenContract = new web3.eth.Contract(JSON.parse(tokenAbiSecond), contractTokenSecond);
                                const wei = window.web3.utils.toWei((formikTwo.values.amount).toString(), "ether")
                                market.methods.updateMinmumamount(wei).send({
                                    from: accounts[0],
                                    to: contractTokenSecond,
                                    value: "0x0",
                                    data: tokenContract.methods.transfer(location?.state?.contract, wei).encodeABI()
                                }, function (err) {
                                    if (err && err.code === 4001) {
                                        toast.error("User denied transaction signature")
                                        setLoading(false)
                                    }
                                }).then(res => {
                                    setLoading(false)
                                    toast.success("Update Minimum Token Added")
                                })
                            } catch (err) {
                                if (err.code === 4902) {
                                    addNetwork(
                                        56,
                                        "BNB TESTNET",
                                        "https://data-seed-prebsc-1-s3.binance.org:8545",
                                        "https://testnet.bscscan.com",
                                        "BNB",
                                        "BNB",
                                        18
                                    )
                                    toast.error(err.message)
                                    setLoading(false)
                                }
                                if (err.code === 4001) {
                                    toast.error(err.message)
                                    setLoading(false)
                                }
                            }
                        }
                    }
                } catch (err) {

                }
            } catch (error) {

            }
        } else if (window.web3) {
            let web3 = new Web3(window.web3.currentProvider);
        } else {
            window.alert('Non-Ethereum browser detected. Please install MetaMask plugin');
        };
    }

    const addLiquidityNativCoin = async (chain, market, contract, address) => {
        if (chain === "BNB" || chain === "ETH") {
            const wei = window.web3.utils.toWei((formik.values.addLiquidity).toString(), "ether")
            let authoized = await market.methods.isAddressAuthorizedLiquidity(address).call();
            if (authoized) {
                market.methods.addLiquidityNativeCoin().send({
                    from: address,
                    to: contractToken,
                    value: wei,

                }, function (err) {
                    if (err && err.code === 4001) {
                        toast.error("User denied transaction signature")
                        setLoading(false)
                    }
                }).then(res => {
                    const add = {
                        "tokenId": tokenID,
                        "amount": formik.values.addLiquidity
                    }
                    socket.emit("add-token-balance", add)
                    setLoading(false)
                    toast.success("Token Balance Added")
                    setTimeout(() => (window.location.href = `/content/addchain/${chainIDValue}`), 1000);
                })
            } else {
                setLoading(false)
                toast.error("Connected address not authorized for add liquidity");
            }
        }
    }

    const contractCall = async () => {
        setShow(!true)
        setLoading(true)
        if (window.ethereum) {
            try {
                let web3 = new Web3(window.ethereum);
                const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                let getChainID = await web3.eth.getChainId();
                await setAddress(accounts[0])
                try {
                    if (window.web3) {
                        await new Web3(window.web3.currentProvider);
                        window.web3 = new Web3(window.web3.currentProvider);
                        if (location.state.chain_symbol === "RCL") {

                            try {
                                await window.ethereum.request({
                                    method: 'wallet_switchEthereumChain',
                                    params: [{ chainId: web3.utils.toHex(121) }]
                                });
                                const market = await new window.web3.eth.Contract(
                                    JSON.parse(process.env.REACT_APP_BRIDGECONTRACT_ABI), location?.state?.contract
                                );

                                var tokenContract = new web3.eth.Contract(JSON.parse(tokenAbi), contractToken);
                                let decimals = await tokenContract.methods.decimals().call();
                                let amount = Number(formik.values.addLiquidity * Math.pow(10, decimals));
                                const wei = window.web3.utils.toWei((formik.values.addLiquidity).toString(), "ether")
                                console.log("decimals",amount, typeof(amount));
                                let authoized = await market.methods.isAddressAuthorizedLiquidity(accounts[0]).call();
                                if (authoized) {
                                    try {
                                        tokenContract.methods.approve(location?.state?.contract, amount.toString()).send({
                                            from: accounts[0],
                                        }, function (err) {
                                            console.log("error", err);
                                            if (err && err.code === 4001) {
                                                toast.error("User denied transaction signature")
                                                setLoading(false)
                                            }
                                        }).then(res => {

                                            market.methods.addLiquidity(contractToken, amount.toString()).send({
                                                from: accounts[0],
                                                to: contractToken,
                                                value: "0x0",
                                                data: tokenContract.methods.transfer(location?.state?.contract, amount.toString()).encodeABI()
                                            }, function (err) {
                                                if (err && err.code === 4001) {
                                                    toast.error("User denied transaction signature")
                                                    setLoading(false)
                                                }
                                            }).then(res => {
                                                const add = {
                                                    "tokenId": tokenID,
                                                    "amount": formik.values.addLiquidity
                                                }
                                                socket.emit("add-token-balance", add)
                                                setLoading(false)
                                                toast.success("Token Balance Added")
                                                setTimeout(() => (window.location.href = `/content/addchain/${chainIDValue}`), 1000);
                                            })
                                        })
                                    } catch (error) {
                                        console.log("error",error)
                                    }
                                } else {
                                    setLoading(false);
                                    toast.error("Connected address not authorized for add liquidity")
                                }
                            } catch (err) {
                                if (err.code === 4902) {
                                    addNetwork(
                                        121,
                                        "Real TESTNET",
                                        "https://dataseed1.rclscan.com/",
                                        "https://testnet.rclscan.com",
                                        "tREAL",
                                        "tREAL",
                                        18
                                    )
                                    setLoading(false)
                                }
                            }
                        } else if (location.state.chain_symbol === "BSC") {
                            try {
                                await window.ethereum.request({
                                    method: 'wallet_switchEthereumChain',
                                    params: [{ chainId: web3.utils.toHex(56) }]
                                });
                                const market = await new window.web3.eth.Contract(
                                    JSON.parse(process.env.REACT_APP_BRIDGECONTRACT_ABI), location?.state?.contract
                                );

                                if (!contractToken) {
                                    addLiquidityNativCoin("BNB", market, location?.state?.contract, accounts[0]);
                                } else {
                                    var tokenContract = new web3.eth.Contract(JSON.parse(tokenAbi), contractToken);
                                    let decimals = await tokenContract.methods.decimals().call();
                                    let amount = Number(formik.values.addLiquidity * Math.pow(10, decimals));
                                    const wei = window.web3.utils.toWei((formik.values.addLiquidity).toString(), "ether")
                                    let authoized = await market.methods.isAddressAuthorizedLiquidity(accounts[0]).call();
                                    if (authoized) {
                                        tokenContract.methods.approve(location?.state?.contract, amount.toString()).send({
                                            from: accounts[0],
                                        }, function (err) {
                                            if (err && err.code === 4001) {
                                                toast.error("User denied transaction signature")
                                                setLoading(false)
                                            }
                                        }).then(res => {
                                            market.methods.addLiquidity(contractToken, amount.toString()).send({
                                                from: accounts[0],
                                                to: contractToken,
                                                value: "0x0",
                                                data: tokenContract.methods.transfer(location?.state?.contract, amount.toString()).encodeABI()
                                            }, function (err) {
                                                if (err && err.code === 4001) {
                                                    toast.error("User denied transaction signature")
                                                    setLoading(false)
                                                }
                                            }).then(res => {
                                                const add = {
                                                    "tokenId": tokenID,
                                                    "amount": formik.values.addLiquidity
                                                }
                                                socket.emit("add-token-balance", add)
                                                setLoading(false)
                                                toast.success("Token Balance Added")
                                                setTimeout(() => (window.location.href = `/content/addchain/${chainIDValue}`), 1000);
                                            })
                                        })
                                    } else {
                                        setLoading(false);
                                        toast.error("Connected address not authorized for add liquidity")
                                    }
                                }

                            } catch (err) {
                                if (err.code === 4902) {
                                    addNetwork(
                                        97,
                                        "BNB TESTNET",
                                        "https://data-seed-prebsc-1-s3.binance.org:8545",
                                        "https://testnet.bscscan.com",
                                        "BNB",
                                        "BNB",
                                        18
                                    )
                                    setLoading(false)
                                }
                                if (err.code === 4001) {
                                    toast.error(err.message)
                                    setLoading(false)
                                }
                            }
                        } else {
                            try {
                                await window.ethereum.request({
                                    method: 'wallet_switchEthereumChain',
                                    params: [{ chainId: web3.utils.toHex(5) }]
                                });
                                const market = await new window.web3.eth.Contract(
                                    JSON.parse(process.env.REACT_APP_BRIDGECONTRACT_ABI), location?.state?.contract
                                );
                                if (!contractToken) {
                                    addLiquidityNativCoin("ETH", market, location?.state?.contract, accounts[0]);
                                } else {
                                    var tokenContract = new web3.eth.Contract(JSON.parse(tokenAbi), contractToken);
                                    let decimals = await tokenContract.methods.decimals().call();
                                    let amount = Number(formik.values.addLiquidity * Math.pow(10, decimals));
                                    const wei = window.web3.utils.toWei((formik.values.addLiquidity).toString(), "ether")
                                    let authoized = await market.methods.isAddressAuthorizedLiquidity(accounts[0]).call();
                                    if (authoized) {
                                        tokenContract.methods.approve(location?.state?.contract, amount.toString()).send({
                                            from: accounts[0],
                                        }, function (err) {
                                            if (err && err.code === 4001) {
                                                toast.error("User denied transaction signature")
                                                setLoading(false)
                                            }

                                        }).then(res => {
                                            market.methods.addLiquidity(contractToken, amount.toString()).send({
                                                from: accounts[0],
                                                to: contractToken,
                                                value: "0x0",
                                                data: tokenContract.methods.transfer(location?.state?.contract, amount.toString()).encodeABI()
                                            }, function (err) {
                                                if (err && err.code === 4001) {
                                                    toast.error("User denied transaction signature")
                                                    setLoading(false)
                                                }
                                            }).then(res => {
                                                const add = {
                                                    "tokenId": tokenID,
                                                    "amount": formik.values.addLiquidity
                                                }
                                                socket.emit("add-token-balance", add)
                                                setLoading(false)
                                                toast.success("Token Balance Added")
                                                setTimeout(() => (window.location.href = `/content/addchain/${chainIDValue}`), 1000);
                                            })
                                        })
                                    } else {
                                        setLoading(false);
                                        toast.error("Connected address not authorized for add liquidity")
                                    }
                                }

                            } catch (err) {
                                if (err.code === 4902) {
                                    addNetwork(
                                        56,
                                        "BNB TESTNET",
                                        "https://data-seed-prebsc-1-s3.binance.org:8545",
                                        "https://testnet.bscscan.com",
                                        "BNB",
                                        "BNB",
                                        18
                                    )
                                    setLoading(false)
                                }
                                if (err.code === 4001) {
                                    toast.error(err.message)
                                    setLoading(false)
                                }
                            }
                        }
                    }
                } catch (err) {

                }
            } catch (error) {

                console.log(error, "error")
                toast.error(error?.message)
                setLoading(false)

            }
        } else if (window.web3) {
            let web3 = new Web3(window.web3.currentProvider);
        } else {
            window.alert('Non-Ethereum browser detected. Please install MetaMask plugin');
        };
    }

    useEffect(() => {
        socket.on("add-token-balance-success", (data) => {
            const details = {
                "id": `${id?.id}`
            }
            socket.emit("tokenAdmin", details)
        })

        socket.on("add-token-balance-faild", (data) => {
            toast.error(data)
        })

        socket.on("update-token-balance-success", (data) => {
            toast.success(data)
            const details = {
                "id": `${id?.id}`
            }
            socket.emit("tokenAdmin", details)
        })

        socket.on("update-token-balance-faild", (data) => {
            toast.error(data)
        })

        socket.on("update-token-status-success", (data) => {
            toast.success(data)
            const details = {
                "id": `${id?.id}`
            }
            socket.emit("tokenAdmin", details)
        })
    }, [socket])

    const handleChangeToggle = (e, id) => {


        setToggle(!toggle)

        if (e.target.checked) {

            const data = {
                "tokenId": id,
                "status": "active"
            }

            socket.emit("update-token-status", data)
        } else {

            const data = {
                "tokenId": id,
                "status": "inactive"
            }

            socket.emit("update-token-status", data)

        }


        // setField('gtc', e.target.checked)


    }


    return (
        <div>
            {loading ? <div className='spinner'>
                <SpinnerCircularFixed size={100} thickness={180} speed={100} color="gold" secondaryColor="rgba(0, 0, 0, 0.44)" />
            </div> : <div className="content-wrapper">

                <section className="content-header">
                    <div className="container-fluid">

                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h5>Add Chain </h5>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right text">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active">Add Chain </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title"></h3>
                                        <div className="box-tools cards">
                                            <h3 style={{ fontSize: "19px" }}>Add Chain </h3>


                                        </div>
                                    </div>


                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table id="example" className="table table-bordered table-striped table-hover" datatable
                                            >
                                                <thead>
                                                    <tr className='tr'>

                                                        <th>Sl.No</th>
                                                        <th>Contract Address</th>
                                                        <th>Edit Status</th>
                                                        <th>Status</th>
                                                        <th>Token Name</th>
                                                        <th>Token Symbol</th>
                                                        <th>Token Balance</th>
                                                        <th>Update Minimum Token</th>
                                                        <th>Withdraw</th>
                                                        <th>Action</th>

                                                    </tr>
                                                </thead>

                                                <tbody>

                                                    {tokenList?.length > 0 && tokenList?.map((e, index) => (

                                                        <>
                                                            <tr className='tr'>

                                                                <td>{index + 1}</td>
                                                                <td>{e?.contract_address !== "" ? e?.contract_address : "----------"}</td>
                                                                <td>
                                                                    <Form>
                                                                        <Form.Check
                                                                            type="switch"
                                                                            id="custom-switch"
                                                                            defaultChecked={e?.status === "active"}
                                                                            onChange={(event) => handleChangeToggle(event, e?._id, "event")}
                                                                        />
                                                                    </Form>
                                                                </td>
                                                                <td>{e?.status}</td>
                                                                <td>{e?.token_name}</td>
                                                                <td>{e?.token_symbol}</td>

                                                                <td>{e?.tokenBalance}</td>

                                                                <td>
                                                                    <Button onClick={() => handleShowSecond(e?.contract_address, e?.contract_ABI, e?._id)} >Update Token</Button>
                                                                </td>
                                                                <td>
                                                                    <Button onClick={() => withdrawToken(e?.contract_address, e?.contract_ABI, e?._id, e?.chain_id)} >Withdraw</Button>
                                                                </td>

                                                                <td>
                                                                    {e.status !== "active" ? <Button variant="primary" className='btnAction' onClick={handleShow}>
                                                                        <i className="fa fa-plus"></i> &nbsp; Add Liqudity
                                                                    </Button> : <Button variant="primary" onClick={() => handleShow(e?.contract_address, e?.contract_ABI, e?._id, e?.chain_id)}>
                                                                        <i className="fa fa-plus"></i> &nbsp; Add Liqudity
                                                                    </Button>}
                                                                </td>



                                                            </tr>

                                                        </>
                                                    ))}




                                                </tbody>


                                            </table>
                                        </div>
                                    </div>

                                </div>



                            </div>

                        </div>


                    </div>

                </section>


                <Footer></Footer>


            </div>}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Liquidity</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <input type="number" name='addLiquidity' value={formik.values.addLiquidity} onChange={formik.handleChange} className='form-control' placeholder="Please Select Amount" ></input>
                        <div className="form-error">{formik.errors.addLiquidity}</div>
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>

                    <Button variant="primary" onClick={() => formik.handleSubmit()}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showSecond} onHide={handleCloseSecond}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Minimum Token</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <input type="number" name='amount' value={formikTwo.values.amount} onChange={formikTwo.handleChange} className='form-control' placeholder="Please Select Amount" ></input>
                        <div className="form-error">{formikTwo.errors.amount}</div>
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseSecond}>
                        Close
                    </Button>

                    <Button variant="primary" onClick={() => formikTwo.handleSubmit()}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>


        </div>
    )
}

export default AddChain