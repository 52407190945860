import React, { useEffect, useState } from 'react';


import '../assets/main.css';
import '../assets/admin.css';
import { socket, Socket } from './SocketStore/Socket';



import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {  useFormik } from 'formik';
import * as yup from "yup";

function Settings() {

    const schema = yup.object().shape({        
        minimum: yup.number().positive()
        .integer("", "Minimum must be positive number")
        .moreThan(0, "Minimum must be positive number")
        .min(0, "Minimum must be positive number")
        .test("check 0", "Minimum must be positive number", (data) => data > 0),
        maximum: yup.number().positive()
        .integer()
        .min(10, "Min is 0")
        .moreThan(yup.ref('minimum'), "Max should be > min"),
        platFormfees: yup.number().positive()
        .integer()
        .min(0, "Min is 0")
               
    });

    const formik = useFormik({
        initialValues: {            
            minimum: '',
            maximum: '',
            platformFees: ''


        },             
        validationSchema: schema,
        onSubmit: values => {
            const newPost = {
                "max_token": values.maximum,
                "min_token": values.minimum,
                "platFormfees": values.platformFees
            }
    
           
            socket.emit("UpdateSettings", newPost)
                    
        },
    }); 

   

    useEffect(() => {

        socket.off('UpdateSettings-res').on("UpdateSettings-res", (data) => {
            toast.success(data)
           
        })

        socket.on("getSettings-res", (data) => {
           

            formik.setFieldValue("minimum", data?.[0]?.min_token)
            formik.setFieldValue("maximum", data?.[0]?.max_token)
            formik.setFieldValue("platformFees", data?.[0]?.platFormfees)
         
        })

    }, [socket])

    useEffect(() => {

        socket.emit("getSettings")
      


    }, [])




    return (

        <section className="content pages">
          

            <div className='mainPage'>
                <h3>General Settings</h3>
            </div>
            <div className="row">
                <div className="firstBox" >
                    <div className='box box-info'>


                        <div className="box-header with-border">
                            <h5 className="title">Manage Settings</h5>
                        </div>

                        
                        <div className='icons'>
                            <i className="fas fa-cog"></i>
                            <li style={{ fontWeight: "800" }}><Link className='link'>Token Contribution Settings</Link></li>
                        </div>
                       



                    </div>
                </div>

                <div style={{ width: "60%" }}>
                    <div className='box box-info'>
                        <h5 className="title mt-3">Token Contribution Settings</h5>

                        <div className='setting'>
                            <div className="path">
                                <label>Minimum Amount</label>
                                <input type="number" name='minimum'  value={formik.values.minimum} onChange={formik.handleChange}></input>
                                
                                
                                
                            </div>
                            <div className="form-error">{formik.errors.minimum}</div>
                            <div className="path">
                                <label>Maximum Amount</label>
                                <input type="number" name='maximum' value={formik.values.maximum} onChange={formik.handleChange}></input>
                                
                            </div>
                            <div className="form-error">{formik.errors.maximum}</div>
                            <div className="path">
                                <label>Platform Fees</label>
                                <input type="number" name='platformFees' value={formik.values.platformFees} onChange={formik.handleChange} style={{ marginLeft: "35px" }}></input>
                               
                            </div>
                            <div className="form-error">{formik.errors.platformFees}</div>
                          


                        </div>
                        <div className='text-center'>
                        <button className='btn btn-primary' onClick={formik.handleSubmit}>Submit</button>
                        </div>
                    </div>


                </div>


              

            </div>

        </section>

    )
}

export default Settings